import React from "react";
import "./DisplayJournals.css";
import JournalDetails from "../../Components/JournalDetails/JournalDetails";
import Footer from "../../Components/Footer/Footer";

export default function DisplayJournalsQic({ jour }) {
  
  const content = {
    ITE: {
      title: "International Transactions On Engineering",
      id: "66433033028a51dd2d264c0f",
      shorttitle: "ITE",
      issn: "2577-610X",
      url: "http://localhost:3000/Assets/Latex_Template.zip",
      intro: [
        `Welcome to the International Transactions on Engineering, a leading
      peer-reviewed journal dedicated to the advancement of engineering
      knowledge and practice. Our journal serves as a vital platform for
      researchers, scholars, and practitioners to share their latest
      findings, insights, and innovations across a wide range of engineering
      disciplines.`,
        `With a commitment to excellence and rigor, the International
      Transactions on Engineering publishes high-quality research papers,
      reviews, and technical notes that contribute to the understanding and
      development of various engineering fields. Our scope encompasses but
      is not limited to mechanical engineering, electrical engineering,
      civil engineering, computer science, and interdisciplinary studies at
      the intersection of engineering and other disciplines.`,
        `With a commitment to excellence and rigor, the International
      Transactions on Engineering publishes high-quality research papers,
      reviews, and technical notes that contribute to the understanding and
      development of various engineering fields. Our scope encompasses but
      is not limited to mechanical engineering, electrical engineering,
      civil engineering, computer science, and interdisciplinary studies at
      the intersection of engineering and other disciplines.`,
      ],
      aim: [
        `The International Transactions on Engineering is a prestigious journal
      devoted to the dissemination of high-quality research in diverse areas
      of engineering. Its aims and scope encompass a broad spectrum of
      topics spanning various branches of engineering, including but not
      limited to mechanical engineering, civil engineering, electrical
      engineering, chemical engineering, and materials science. The journal
      provides a platform for researchers, engineers, academicians, and
      industry professionals to publish original research articles, reviews,
      and innovative contributions that advance the understanding and
      application of engineering principles and technologies. It welcomes
      interdisciplinary research that bridges traditional boundaries and
      addresses emerging challenges at the interface of different
      engineering disciplines. The journal strives to promote the exchange
      of ideas, foster collaboration, and stimulate innovation in
      engineering by providing a forum for scholarly communication and
      dissemination of cutting-edge research findings. Through rigorous peer
      review and editorial processes, the International Transactions on
      Engineering aims to maintain the highest standards of scientific
      integrity and contribute to the advancement of knowledge and
      technological progress in the field of engineering.`,
      ],
      cheifEditor: [
        "Dr. Dorsala Malikarjun Reddy",
      ],
      Designation:["Assistant Professor"],
      cheifEditorEmail: ["editor_ite@ranvenpublisher.com"],
      Institute:["Indian Institute of Information Technology Sri City"],
      Profile: ["https://www.iiits.ac.in/people/regular-faculty/dr-dorsala-mallikarjun-reddy/"] 

    },
    ITEE: {
      title: "International Transactions on Electronic Engineering",
      id: "66464e36a188cc2e078b4961",
      shorttitle: "ITEE",
      issn: "2577-610X",
      url: "http://localhost:3000/Assets/Latex_Template.zip",
      intro: [
        `The International Transactions on Electronic Engineering stands as a beacon
         of excellence in the realm of electronic engineering, dedicated to the dissemination
          of top-tier research and innovation. This esteemed journal boasts a comprehensive
           scope, spanning a wide array of topics crucial to the field's advancement.`,
        `Covering everything from electronic circuits and semiconductor devices to
         integrated circuits, analog and digital electronics, microelectronics, VLSI
          design, electronic instrumentation, power electronics, and emerging electronic
           technologies, the journal encapsulates the breadth and depth of electronic engineering.`,
        `At its core, the journal provides a vital platform for researchers, scholars, 
        engineers, and practitioners to showcase their original research articles, 
        reviews, and technical notes. By welcoming contributions that push the boundaries 
        of electronic engineering theory, methodologies, and applications, the journal 
        serves as a catalyst for innovation and progress.`,
        `Moreover, the journal recognizes the interconnected nature of modern engineering 
        disciplines and actively encourages interdisciplinary research. By integrating 
        electronic engineering with related fields such as computer science, telecommunications, 
        and biomedical engineering, the journal fosters collaboration and cross-pollination of 
        ideas, driving forward transformative advancements with real-world impact.`,
        `In essence, the International Transactions on Electronic Engineering 
        serves as a nexus for the exchange of ideas and knowledge, fostering 
        innovation, addressing challenges, and driving advancements in electronic 
        engineering for the betterment of society as a whole.`,
      ],
      aim: [
        `The International Transactions on Electronic Engineering is a 
        reputable journal committed to the dissemination of high-quality
         research in the field of electronic engineering. With a comprehensive 
         aims and scope, the journal covers a broad spectrum of topics including 
         electronic circuits, semiconductor devices, integrated circuits, analog 
         and digital electronics, microelectronics, VLSI design, electronic instrumentation,
          power electronics, and emerging electronic technologies. It provides a platform for 
          researchers, scholars, engineers, and practitioners to publish original research 
          articles, reviews, and technical notes that contribute to the advancement of 
          electronic engineering theory, methodologies, and applications. The journal 
          welcomes interdisciplinary research that integrates electronic engineering 
          with other related disciplines such as computer science, telecommunications, 
          and biomedical engineering. By facilitating the exchange of ideas and knowledge,
           the journal aims to foster innovation, address challenges, and drive advancements 
           in electronic engineering for the benefit of society.`,
      ],
    
      cheifEditor: [
        "Dr. Chandrashekar Jatoth",
      ],
      Designation:["Assistant Professor"],
      cheifEditorEmail: ["editor_ee@ranvenpublisher.com"],
      Institute:["National Institute of Technology Raipur"],

    },
    ITACST: {
      title:
        "International Transactions on Advanced Computer Science Technology",
      shorttitle: "ITACST",
      id: "66464fdea188cc2e078b4963",
      issn: "2577-610X",
      url: "http://localhost:3000/Assets/Latex_Template.zip",
      intro: [
        `The International Transactions on Advanced Computer Science Technology 
        stands as a premier scholarly journal at the forefront of disseminating
         groundbreaking research in the expansive realm of computer science and 
         technology. Embracing a broad spectrum of disciplines within the field, 
         the journal serves as a beacon for researchers and practitioners alike, 
         offering a platform for the exchange of innovative ideas and the exploration 
         of cutting-edge developments.`,
        `At its core, the journal encompasses a rich tapestry of topics, 
        including but not limited to artificial intelligence, machine learning, 
        data mining, computer vision, natural language processing, algorithms, 
        computer networks, cybersecurity, and software engineering. This comprehensive 
        scope reflects the diverse and rapidly evolving landscape of contemporary computer science, 
        recognizing the interconnectedness and interplay among various subfields.`,
        `Within this dynamic milieu, the journal welcomes original research articles, 
        insightful reviews, and pioneering contributions that push the boundaries of 
        knowledge and advance the practical applications of computer science principles. 
        By fostering a culture of academic rigor and intellectual curiosity, the journal 
        seeks to empower researchers, academics, professionals, and students to delve into 
        the intricacies of theoretical analysis, experimental exploration, and real-world implementation.`,
        `Moreover, the journal's overarching goal extends beyond the mere dissemination 
        of knowledge; it aspires to catalyze interdisciplinary collaboration and facilitate 
        the cross-pollination of ideas. By nurturing a vibrant ecosystem of scholarly 
        discourse and collaboration, the journal endeavors to address the multifaceted 
        challenges and seize the myriad opportunities presented by the ever-evolving 
        landscape of computer science and technology.`,
        `In essence, the International Transactions on Advanced Computer Science Technology 
        serves as a dynamic hub for intellectual exchange, innovation, and discovery, 
        driving forward the frontiers of computer science and technology in pursuit of 
        a brighter, more technologically advanced future.`,
      ],
      aim: [
        `The International Transactions on Advanced Computer Science Technology
         is a scholarly journal that provides a platform for the dissemination 
         of cutting-edge research in the field of computer science and technology.
          With a broad aims and scope, the journal encompasses various aspects of 
          advanced computer science technologies including but not limited to artificial 
          intelligence, machine learning, data mining, computer vision, natural language 
          processing, algorithms, computer networks, cybersecurity, and software 
          engineering. It welcomes original research articles, reviews, and innovative 
          contributions that advance the understanding and application of computer 
          science principles and technologies. The journal targets researchers, academics, 
          professionals, and students interested in exploring and expanding the frontiers 
          of computer science through rigorous theoretical analysis, experimentation, and 
          practical applications. Its overarching goal is to foster interdisciplinary 
          collaboration and facilitate the exchange of ideas to address emerging 
          challenges and opportunities in the rapidly evolving landscape of computer 
          science and technology.`,
      ],
      cheifEditor: [
        "Dr. Kartick Sutradhar",
      ],
      Designation:["Assistant Professor"],
      cheifEditorEmail: ["editor_acst@ranvenpublisher.com"],
      Institute:["Indian  of Information Technology Sri City"],
      Profile: ["https://www.iiits.ac.in/people/regular-faculty/dr-kartick-sutradhar/"] 
    },
    ITCEE: {
      title:
        "International Transactions on Computer and Electrical Engineering",
      shorttitle: "ITCEE",
      id: "66465170a188cc2e078b4965",
      issn: "2577-610X",
      url: "http://localhost:3000/Assets/Latex_Template.zip",
      intro: [
        `The International Transactions on Computer and Electrical Engineering is a 
        scholarly beacon illuminating the interdisciplinary landscape of computer 
        and electrical engineering. With a steadfast dedication to fostering 
        knowledge and innovation, the journal serves as a cornerstone for researchers, 
        academics, engineers, and practitioners seeking to push the boundaries 
        of these dynamic fields.`,
        `Encompassing a broad spectrum of topics, the journal's aims and scope span 
        across diverse domains, including computer architecture, embedded systems, 
        signal processing, control systems, power electronics, telecommunications, 
        and renewable energy systems. This comprehensive coverage reflects the 
        intricate interplay between computing and electrical engineering disciplines, 
        acknowledging their pivotal roles in shaping our technological future.`,
        `At its core, the journal provides a vital platform for the dissemination 
        of original research findings, reviews, and technical notes that contribute 
        to the advancement of theory and practice in computer and electrical engineering. 
        By welcoming contributions that delve into fundamental principles, methodologies, 
        technologies, and real-world applications, the journal catalyzes the evolution 
        of these fields and facilitates the translation of theoretical insights into 
        tangible solutions.`,
        `Furthermore, the journal champions interdisciplinary research, recognizing that 
        innovation often thrives at the intersection of multiple disciplines. By promoting 
        collaboration and knowledge exchange, it cultivates an environment where diverse 
        perspectives converge to tackle complex challenges and forge new frontiers in technology.`,
        `In essence, the International Transactions on Computer and Electrical Engineering
         is more than a repository of knowledge; it is a catalyst for innovation, a 
         nexus for collaboration, and a driving force behind technological advancement. 
         Through its tireless efforts to promote interdisciplinary research, facilitate 
         technological advancements, and address real-world problems, the journal embodies
          the spirit of progress that defines the computer and electrical engineering communities.`,
      ],
      aim: [
        `The International Transactions on Computer and Electrical Engineering
         is a scholarly journal dedicated to advancing knowledge and innovation in
          the interdisciplinary fields of computer and electrical engineering. The
           journal's aims and scope encompass a wide range of topics including,
            but not limited to, computer architecture, embedded systems, signal processing,
             control systems, power electronics, telecommunications, and renewable energy 
             systems. It provides a platform for researchers, academics, engineers,
              and practitioners to publish original research findings, reviews, and 
              technical notes that contribute to the advancement of theory and practice 
              in computer and electrical engineering. The journal welcomes contributions 
              that address fundamental principles, methodologies, technologies, and 
              applications in these fields, with a particular emphasis on interdisciplinary 
              research and practical solutions to real-world problems. By fostering 
              collaboration and knowledge exchange, the journal seeks to promote 
              innovation, facilitate technological advancements, and address the 
              complex challenges facing the computer and electrical engineering 
              communities.`,
      ],
      cheifEditor: [
        "Dr. Ruhul Amin",
      ],
      Designation:["Assistant Professor"],
      cheifEditorEmail: ["editor_cee@ranvenpublisher.com"],
      Institute:["Indian Institute of Information Technology Naya Raipur"],
      Profile: ["https://www.iiitnr.ac.in/content/ruhul-amin"] 
    },
  };
  return (
    <div className="DisplayJournalsContainer">
      <JournalDetails content={content[jour] } email={content.cheifEditorEmail} />
      <Footer />
    </div>
  );
}
