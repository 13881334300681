import React from 'react'
import './Realnavbar.css'
import { Link } from 'react-router-dom'
const  logo ="/Assets/Images/logo.svg"
const RealNavbar = () => {
  return (
    <div className="first-div">
      <div className="navbar_logocontainer">
      <Link to="/"  style={{color:"white",textDecoration:"none"}}>   <img src={logo} alt="" /></Link>
      </div>
      <div className="navbar_logo">RanVen</div>
        <div className="left-links" style={{marginLeft:"1.5%"}}>
          <button>  <Link to="/"  style={{color:"white",textDecoration:"none"}}>Home</Link> </button>
          <button>Magazine</button>

          <button>Books</button>
          <button> <Link to="/"  style={{color:"white",textDecoration:"none"}}>Journals</Link> </button>
    
        </div>
      
      </div>
  )
}

export default RealNavbar
