import React, { useState } from "react";
import "./CustomDropdown.css";
import { Link } from "react-router-dom";
const CustomDropdown = ({dynamicClass,seq_arr,issueId_arr,issueMonth_arr,Volume,stringData}) => {
  // console.log(seq_arr);
  // console.log(issueId_arr);
  // console.log(issueMonth_arr);
  // Volume: 1&nbsp;&nbsp;&nbsp;&nbsp;Issue: 1 (May)
  return (
   
   
      
        <div className={`CustomDropdown-Content ${dynamicClass} `} >
          {
            seq_arr && seq_arr.map((seq,index)=>{
              return (
                <Link to={`/tb_content/issue/${issueId_arr[index]}`} className="CustomDropdown-Content-Link" key={index}>
                <div className="CustomDropdown-item">{`Volume:${Volume} Issue: ${seq_arr[index]} (${issueMonth_arr[index].charAt(0).toUpperCase() + issueMonth_arr[index].substr(1)}) `}</div>
                </Link>
              )
            })
          }
          
        </div>
      
  
  );
};
export default CustomDropdown;
