import React, { useState, useEffect } from "react";
import "./TbContent.css";
import TbPaperContainer from "./TbPaperContainer";
import CustomDropdown from "../../Components/CustomDropdownList/CustomDropdown";

const TbContent = ({ content, journalId }) => {
  // console.log(content);
  const [mappedUniqueVolume, setMappedUniqueVolume] = useState(new Map());
  const [showDetails, setShowDetails] = useState(true);
  const [selectedVidId, setSelectedVidId] = useState(null);
  console.log(mappedUniqueVolume);
  useEffect(() => {
    const mapUniqueVolume = () => {
      let uniqueVolume = new Map();
      content?.Issues.forEach(issue => {
        // console.log(typeof(issue.journal._id));
        if (issue.journal._id === journalId) {
          const { volumenumber, volumeyear, issuenumber,issueMonth ,_id } = issue;
          if (!uniqueVolume.has(volumenumber)) {
            uniqueVolume.set(volumenumber, {
              volumeyear,
              issueId:[_id],
              issuenumber: [issuenumber],
              issueMonth:[issueMonth]
            });
          } else {
            let valueObj = uniqueVolume.get(volumenumber);
            valueObj.issuenumber.push(issuenumber);
            valueObj.issueMonth.push(issueMonth);
            valueObj.issueId.push(_id);
            valueObj.issuenumber.sort((a, b) => a - b);
            uniqueVolume.set(volumenumber, valueObj);
          }
        }
      });
      setMappedUniqueVolume(uniqueVolume);
      // console.log(uniqueVolume);
    };

    mapUniqueVolume();
  }, [content, journalId]);

  const handleClick = (mpkey) => {
    // console.table([mpkey,typeof(mpkey),selectedVidId===mpkey]);
    setSelectedVidId(selectedVidId === mpkey ? null : mpkey);
  };

  return (
    <div className="tbContent_main-container">
      {[...mappedUniqueVolume.entries()].map(([mpkey, value]) => (
        <div className="tb-dropdown-handle-container" key={mpkey}>
          <div
            className="tb-paper-container"
            onClick={() => handleClick(mpkey)}
          >
            <TbPaperContainer vid={value} uniquempkey={mpkey} selectedVidId={selectedVidId} />
          </div>
          {selectedVidId === mpkey && (
            <CustomDropdown
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              dynamicClass='selectedDropdown'
              seq_arr={value.issuenumber}
              issueId_arr={value.issueId}
              issueMonth_arr={value.issueMonth}
              Volume={mpkey}
              stringData={``}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default TbContent;

