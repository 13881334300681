import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { PiFilePdfThin } from "react-icons/pi";
import "./Tb_content_paper_container.css";
import { Link } from "react-router-dom";
const Tb_content_paper_container = ({
  Papername,
  authors,
  publicationyear,
  abstract,
  pdfLink,
  open,
  change,
  ind,
  doi,
  doivalue
}) => {
  const openPDF = () => {
    window.open(pdfLink);
  };
  console.log(pdfLink, Papername);
  return (
    <div className="Tb_content_papercontainer">
      <header><Link to={`/doi/${doi}`} style={{textDecoration:"none",color:"#286fb4"}}>{Papername}</Link></header>
      <p>
        {authors}
      </p>
      <div className="publication_info_tbcontent">
        <span>
          {" "}
          <b> Publication Yea</b>r: {publicationyear}
          {/* ,<b> Page(s)</b>:72 - 80 */}
        </span>
        {/* <span>
          {" "}
          <b>Cited by</b>:3
        </span> */}
      </div>
      <div className="tb_content_paper_setting">
        <span id="abstract" onClick={()=>change(ind)}>
          {" "}
          <FaChevronDown /> Abstract
        </span>
        <span
          id="pdf_btn"
          style={{
            background: "#ff3500",
            color: "white",
            fontSize: "11px",
            fontWeight: "600px",
            gap: "3px",
          }}
          onClick={openPDF}
        >
          {" "}
          <PiFilePdfThin />
          Pdf
        </span>
        <div>
          DOI: <Link to={`/doi/${doi}`}><span id="doi_link">{doivalue}</span></Link>
        </div>
      </div>
      {open && <div className="abstractDivSmallPaper">
        {abstract}
      </div>}
     
    </div>
  );
};

export default Tb_content_paper_container;
