import React from "react";
import "./EditorialBoard.css";
import { Link } from "react-router-dom";
const editorialBoardMembers = [
  {
    Name: "Dr. Ruhul Amin",
    Institute: "International Institute of Information Technology Naya Raipur",
    Designation: "Assistant Professor",
    Email: "amin_ruhul@live.com",
    Profile: "https://www.iiitnr.ac.in/content/ruhul-amin",
  },
  {
    Name: "Dr. Dorsala Mallikarjun Reddy",
    Institute: "Indian Institute of Information Technology Sri City",
    Designation: "Assistant Professor",
    Email: "mallikarjunreddy.d@iiits.in",
    Profile:
      "https://www.iiits.ac.in/people/regular-faculty/dr-dorsala-mallikarjun-reddy/",
  },
  {
    Name: "Dr. Ajay Kumar Mallick",
    Institute: "National Institute of Technology Hamirpur",
    Designation: "Assistant Professor",
    Email: "ajaymallick@nith.ac.in",
    Profile:
      "https://portfolios.nith.ac.in/index.php?/nith/dr-ajay-kumar-mallick",
  },
  {
    Name: "Dr. Kartick Sutradhar",
    Institute: "Indian Institute of Information Technology Sri City",
    Designation: "Assistant Professor",
    Email: "kartick.s@iiits.in",
    Profile:
      "https://www.iiits.ac.in/people/regular-faculty/dr-kartick-sutradhar/",
  },
  {
    Name: "Dr. Pranay Kumar Saha",
    Institute: "Indian Institute of Technology Dhanbad",
    Designation: "Assistant Professor",
    Email: "pranay@iitism.ac.in",
    Profile: "https://people.iitism.ac.in/~download/cv/1570/1570.pdf",
  },
  {
    Name: "Dr. Sandeep Singh Sengar",
    Institute: "Cardiff Metropolitan University, United Kingdom",
    Designation: "Lecturer",
    Email: "SSSengar@cardiffmet.ac.uk",
    Profile:
      "https://www.cardiffmet.ac.uk/technologies/staff-profiles/Pages/SandeepSingh-Sengar.aspx",
  },
  {
    Name: "Dr. Praveen Kumar Donta",
    Institute: "Stockholm University, Sweden",
    Designation: "Senior Lecturer",
    Email: "praveen.donta@dsv.su.se",
    Profile: " https://www.su.se/english/profiles/prdo2937-1.742888",
  },
];
const EditorialBoard = () => {
  return (
    <div className="editorialBoard-MainContainer">
      <div className="editorialBoard-mainHeading">
        <span>Editorial Board Members</span>
      </div>
      {editorialBoardMembers.map((member) => {
        return (
          <div
            className="editorialBoard-Container"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "#286fb4",
                marginLeft: "0.8rem",
                marginTop: "0.4rem",
                letterSpacing: "0.4px",
              }}
            >
              Name :{" "}
           
              <span style={{ color: "black", fontWeight: "400" }}>
                {member.Name}
              </span>
            </span>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "#286fb4",
                marginLeft: "0.8rem",
                letterSpacing: "0.4px",
              }}
            >
              Institute :{" "}
              <span style={{ color: "black", fontWeight: "400" }}>
                {" "}
                {member.Institute}
              </span>
            </span>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "#286fb4",
                marginLeft: "0.8rem",
                letterSpacing: "0.4px",
              }}
            >
              Designation :{" "}
              <span style={{ color: "black", fontWeight: "400" }}>
                {" "}
                {member.Designation}
              </span>
            </span>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "#286fb4",
                marginLeft: "0.8rem",
                letterSpacing: "0.4px",
              }}
            >
              Email :{" "}
              <span style={{ color: "black", fontWeight: "400" }}>
                {" "}
                {member.Email}
              </span>
            </span>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "#286fb4",
                marginLeft: "0.8rem",
                marginBottom: "0.4rem",
                letterSpacing: "0.4px",
              }}
            >
              Profile :{" "}
              <Link to={member.Profile} style={{textDecoration:"none"}}>
              <span style={{ color: "black", fontWeight: "400", }}>
                {" "}
                {member.Profile}
              </span>
              </Link>
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default EditorialBoard;
