import React from "react";
import { FaSearch } from "react-icons/fa"; // Import FaSearch icon from react-icons/fa
import { FaAnglesDown } from "react-icons/fa6";
import "./Navbar.css"; // Import your CSS file for styling
const  logo ="./Assets/Images/logo.svg"
const Navbar = ({handleScroll,isInView}) => {
  return (
    <div className="navbar">
     
      <div className="company_name">
        <div>
        <img src={logo} alt="" />
        <span>
          RanVen </span>
          </div>
    
      </div>
      <div className="third-div">
        <div className="search-bar">
          <input type="text" placeholder="Search..." />
          <button className="search-button">
            <FaSearch />
          </button>{" "}
         
        </div>
      </div>
      <div className="landingpage_webinfo">
        <p style={{fontSize:"1rem"}}><b>Technological Imrovements for the Betterment of Humanity</b></p></div>
    <div className="landingpage_downmenu">
     {isInView&& <FaAnglesDown  onClick={handleScroll}/>}
      </div>
    </div>
  );
};

export default Navbar;
