import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  // Link
} from "react-router-dom";
import LandingPage from "./Pages/Landingpage/LandingPage";
import DisplayJournalsQic from "./Pages/Display Journals/DisplayJournalsQic";
import RealNavbar from "./Components/Navbar/RealNavbar";
import NotFound from "./Pages/Pages Not Found/NotFound";
import TbMainLayout from "./Pages/TableOfContent/TbMainLayout/TbMainLayout";
import Paper_list_page from "./Pages/paper_in_issue/Paper_list_page";
import Doi from "./Pages/DOI/Doi";
import JournalContactUs from "./Components/JournalDetails/JournalContactUs";
import EditorialBoard from "./Components/JournalDetails/EditorialBoard/EditorialBoard";
const pathArr = [
  "/display/journals/editorialBoard",  //static

  "^/display/journals/ite$",//dynamic
  "^/display/journals/itee$",
  "^/display/journals/itcee$",
  "^/display/journals/itacst$",
  "^/tb_content/[^/]+$", // Regex pattern for /tb_content/:journalId
  "^/tb_content/issue/[^/]+$",
  "^/doi/[^/]+$", // Regex pattern for /doi/:paperid
  "^/display/journals/ite/contactus$",
  "^/display/journals/itee/contactus$",
  "^/display/journals/itcee/contactus$",
  "^/display/journals/itacst/contactus$",
];
function AllRoutes() {
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or the route changes
  }, [path]);

  return (
    <>
      {pathArr.some((pattern) => new RegExp(pattern).test(path)) && (
        <RealNavbar />
      )}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/display/journals/ite"
          element={<DisplayJournalsQic jour="ITE" />}
        />
        <Route
          path="/display/journals/itee"
          element={<DisplayJournalsQic jour="ITEE" />}
        />
        <Route
          path="/display/journals/itcee"
          element={<DisplayJournalsQic jour="ITCEE" />}
        />
        <Route
          path="/display/journals/itacst"
          element={<DisplayJournalsQic jour="ITACST" />}
        />

        <Route
          path="/display/journals/ite/contactus"
          element={<JournalContactUs jour="ITE" />}
        />
        <Route
          path="/display/journals/itee/contactus"
          element={<JournalContactUs jour="ITEE" />}
        />
        <Route
          path="/display/journals/itcee/contactus"
          element={<JournalContactUs jour="ITCEE" />}
        />
        <Route
          path="/display/journals/itacst/contactus"
          element={<JournalContactUs jour="ITACST" />}
        />
        <Route
          path="/display/journals/editorialBoard"
          element={<EditorialBoard />}
        />

        {/* Table Of content */}
        <Route path="/tb_content/:journalId" element={<TbMainLayout />} />

        {/* Issue List Page */}
        <Route path="/tb_content/issue/:Issue" element={<Paper_list_page />} />

        {/* Doi page */}
        <Route path="/doi/:paperid" element={<Doi />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default AllRoutes;
