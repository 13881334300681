import React from 'react'
import './authorcard.css'
import { Link } from 'react-router-dom'
const Authorcard = ({name,img,url,dynamic_styles={}}) => {
  return (
    <>
    <div className="journal_cards" style={dynamic_styles}>
     {dynamic_styles?.pointerEvents&& dynamic_styles.pointerEvents==='none'&&<span id='Comming_soon'> <b>Coming soon ....</b></span>}
       <header> <Link to={url} state={{color:""}} >{name}</Link>  </header>
       <div className="journal_body">
        <div className="journal_img"><img src={img} alt="" /></div>
        <div className="journal_card_links">
        <div className="journal_card_link">
         <div className="link">
          <span>Hybrid Journal</span>
         </div>

        </div>
        <div className="journal_card_visit">
       <Link to={url} id='out_paper_link'>More Info</Link>
        </div>
        </div>
        
       </div>

    </div>
    </>
  )
}

export default Authorcard
