import React, { useRef, useEffect, useState } from "react";
import "./LandingPage.css";
import Authorcard from "./Authorcard/Authorcard";
import Navbar from "../../Components/Navbar/Navbar";
const  img1 = "./Assets/Images/Computer_and_electrical.jpeg";
const  img2 = "./Assets/Images/Computerscience.jpeg";
const img3 = "./Assets/Images/Electronics.jpeg";
const  img4 = "./Assets/Images/Engineering.jpeg";

const LandingPage = () => {
  const [isInView, setIsInView] = useState(true);
  const scrollToSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.01,
      }
    );

    const currentRef = scrollToSectionRef.current; // Copy ref to a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []); // Empty dependency array to run only once

  const handleScroll = () => {
    if (scrollToSectionRef.current) {
      scrollToSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar handleScroll={handleScroll} isInView={isInView} />
      <div className="Main-Container" ref={scrollToSectionRef}>
        <div className="maincontainer_authors">
          <span>Journals</span>
          <div className="line" style={{ width: "64%" }}></div>
          <div className="authors">
            <div className="journals_card_container">
              <Authorcard
                name="International Transactions on Computer and Electrical Engineering"
                img={img1}
                url={"/display/journals/itcee"}
                />
              <Authorcard
                name="International Transactions on Advanced Computer Science Technology"
                img={img2}
                url={"/display/journals/itacst"}
                
              />
            </div>
            <div className="journals_card_container">
             
              <Authorcard
                name="International Transactions on Electronic Engineering"
                img={img3}
                url={"/display/journals/itee"}
                dynamic_styles={{pointerEvents: 'none',opacity:0.6}}
                />
              <Authorcard
                name="International Transactions on Engineering"
                img={img4}
                url={"/display/journals/ite"}
                dynamic_styles={{pointerEvents: 'none',opacity:0.6}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
