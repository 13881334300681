import React, { useEffect, useState } from "react";

import Tb_content_paper_container from "./Tb_content_paper_container";
import "./Paper_list_page.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {apiUrl}from '../../App'

const img1 = "/Assets/Images/Computer_and_electrical.jpeg";
const img2 = "/Assets/Images/Computerscience.jpeg";
const img3 = "/Assets/Images/Electronics.jpeg";
const img4 = "/Assets/Images/Engineering.jpeg";
const Paper_list_page = ({ volume, issue }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [journal,setJournal] =useState("");
  const navigate = useNavigate();
  const [content, setContent] = useState();
  const [showAbstract,setShowAbstract] = useState([]);
  const {Issue} = useParams();
  // const Issue = "664343efa9195c0f2337d1cb";
  useEffect(function () {
    const controller = new AbortController();
    const signal = controller.signal;
    async function getIssuesAndVolume() {
      try {
        setIsLoading(true);
        const response = await axios.get(`${apiUrl}/api/v1/backdoor-upload/paper`, {
          params: { Issue: Issue },
        });
        const data = response.data;
        console.log(data);
        const arr = Array(data.papers.length).fill(false);
        setShowAbstract(arr);
        setContent(data);
        setJournal(data.issue?.journal?.journalname);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          navigate("/notfound");
        }
      }
    }

    getIssuesAndVolume();

    return () => {
      controller.abort(); // Cleanup function to abort the Axios request
    };
  }, []);
  const openAbstract = (ind)=>{
    const arr = [
      ...showAbstract.slice(0,ind),
      !showAbstract[ind],
      ...showAbstract.slice(ind+1)
    ]
    setShowAbstract(arr);
  }
  return (
    <div className="tb_content_issue_paper">
      <div
        className="TbMainLayout_header issueheader"
        style={{ padding: "4px 0", width: "77%" }}
      >
        <div
          className="TbMainLayout_img_container"
          style={{ width: "15%", height: "fit-content" }}
        >
          {journal === "International Transactions on Computer and Electrical Engineering"  && (
            <img src={img1} className="TbMainLayout_img" alt="" />
          )}
          {journal === "International Transactions on Advanced Computer Science Technology" && (
            <img src={img2} className="TbMainLayout_img" alt="" />
          )}
          {journal === "International Transactions on Electronic Engineering" && (
            <img src={img3} className="TbMainLayout_img" alt="" />
          )}
          {journal ==="International Transactions On Engineering" && (
            <img src={img4} className="TbMainLayout_img" alt="" />
          )}
        </div>
        <div className="TbMainLayout_BookName_container issue_Name_container">
          {journal === "International Transactions On Engineering" && (
            <div className="TbMainLayout_BookName">
              International Transactions On Engineering
            </div>
          )}
          {journal === "International Transactions on Electronic Engineering" && (
            <div className="TbMainLayout_BookName">
              International Transactions on Electronic Engineering
            </div>
          )}
          {journal === "International Transactions on Advanced Computer Science Technology" && (
            <div className="TbMainLayout_BookName">
              International Transactions on Advanced Computer Science Technology
            </div>
          )}
          {journal === "International Transactions on Computer and Electrical Engineering" && (
            <div className="TbMainLayout_BookName">
              International Transactions on Computer and Electrical Engineering
            </div>
          )}
        </div>
      </div>
      <header>
        <span style={{fontSize:"1.5rem"}}>All Papers (Volume: {content && content.issue.volumeyear -2023} , Issue: {content && content.issue.issuenumber})</span> 
      </header>
      <body style={{ gap: "7px" }}>
        {content &&
          content.papers.map((paper, ind) => (
            <Tb_content_paper_container
              Papername={paper.papertitle}
              authors={paper.authorname}
              publicationyear={paper.yearofpublication}
              abstract={paper.abstract}
              pdfLink={paper.pdflink}
              open={showAbstract[ind]}
              change={openAbstract}
              ind={ind}
              doi={paper._id}
              doivalue={paper?.Doi}
            />
          ))}
      </body>
    </div>
  );
};

export default Paper_list_page;
