import React, { useEffect, useState } from "react";
import TbContent from "../TbContent";
import "./TbMainLayout.css";
import NormalBtn from "../../../Components/Buttons/NormalBtn/NormalBtn";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../../../Components/Footer/Footer";
import Spinner from "../../../Components/Spinner/Spinner";
import {apiUrl}from '../../../App'
const ITEImage= "/Assets/Images/ITE.jpg";
const ITEEImage= "/Assets/Images/ITEE.jpg";
const ITACSTImage= "/Assets/Images/ITACST.jpg";
const ITCEEImage= "/Assets/Images/ITCEE.jpg";

const img = "/Assets/Images/journal.jpg";
// {
//   _id: 10,
//   Vol: "10",
//   Year: 2017,
//   seqno:['Issues 1','Issues 2','Issues 3','Issues 4','Issues 5','Issues 6','Issues 7','Issues 8','Issues 9','Issues 10','Issues 11']

// }

const TbMainLayout = () => {
  const { journalId } = useParams();
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(
    function () {
      const controller = new AbortController();
      const signal = controller.signal;
      async function getIssuesAndVolume() {
        try {
          setIsLoading(true);
          const res = await axios.post(
            `${apiUrl}/api/v1/backdoor-upload/allissue`,
            { journalId },
            { signal }
          );
          const data = res.data;
          console.log(data);
          setIsLoading(false);
          if (!data.success) {
            navigate("/notfound");
          } else {
            setContent(data);
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.log(error);
            setIsLoading(false);
            navigate("/notfound");
          }
        }
      }

      getIssuesAndVolume();

      return () => {
        controller.abort(); // Cleanup function to abort the Axios request
      };
    },
    [journalId, navigate]
  );

  const openpdf = (content) => {
    // console.log(content);
    if (content) window.open(content?.Issues[0]?.journal?.guideline, "_blank");
    else return;
  };
  const downloadzipfile = () => {
    const url = "/Assets/Latex_Template.zip";
    const fileName = "Latex Template";
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(blob);
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
        window.URL.revokeObjectURL(blobURL);
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      {" "}
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="TbMainLayoutMainContainer">
          <div className="TbMainLayout_heading">
            <div className="TbMainLayout_BookName_container">
              <div className="TbMainLayout_BookName">
                {content?.Issues[0]?.journal?.journalname}{" "}
                {/* <span className="DisplayJournalsMainContentTitleHeadSpan">
                  ( {content?.Issues[0]?.journal?.journalshortform} )
                </span> */}
              </div>
            </div>
            <div className="TbMainLayout_img_container">
              {/* <img src={img} className="TbMainLayout_img" alt="" /> */}

              <img
                src={content?.Issues[0]?.journal?.imagepath}
                alt={`${content?.Issues[0]?.journal?.journalshortform}`}
                loading="lazy"
                className="TbMainLayout_img"
              />

              <div className="TbMainLayout_BookName_btn">
                {/* </Link> */}
                <span onClick={() => downloadzipfile(content)}>
                  <NormalBtn info={`LaTex Templates`} btnstyle={`stl`} />
                </span>
                <span onClick={() => openpdf(content)}>
                  <NormalBtn info="Guideline for Authors" btnstyle={`stl`} />
                </span>
                <NormalBtn
                  info="Abstract & Indexed"
                  btnstyle={`disabled stl`}
                />
              </div>
            </div>
          </div>
          <TbContent content={content} journalId={journalId} />
          <div className="TbMainLayout_footer">
            {/* <div className="DisplayJournalsMainContentBodyHead">
              Introduction 
            </div> */}
            {content?.Issues[0]?.journal?.intro.map((paragraph, ind) => {
              return (
                <div className="TbMainLayout_footer_body" key={ind}>
                  {paragraph}
                </div>
              );
            })}
          </div>
          <Footer />
        </div>
      )}
      
    </>
  );
};

export default TbMainLayout;
