import AllRoutes from "./AllRoutes";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RealNavbar from "./Components/Navbar/RealNavbar";
import { useEffect } from "react";


export const apiUrl = "https://api.ranvenpublisher.com";

function App() {

  return (
    <div className="App-container">
      <Router>
       <AllRoutes />
      </Router>
    </div>
  );
}

export default App;
