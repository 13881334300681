import React from "react";
import JournalContactUsDetails from "./JournalContactUsDetails";
import Footer from "../Footer/Footer";

export default function JournalContactUs({ jour }) {
  const content = {
    ITCEE: {
      title:
        "International Transactions on Computer and Electrical Engineering",
      shorttitle: "ITCEE",
      editorName: "Dr. Ruhul Amin",
      editorDesignation: "Assistant Professor",
      editorEmail: "editor_cee@ranvenpublisher.com",
      editorInstitute: "Indian Institute of Information Technology Naya Raipur",
    },
    ITACST: {
      title:
        "International Transactions on Advanced Computer Science Technology",
      shorttitle: "ITACST",
      editorName: "Dr. Kartick Sutradhar",
      editorDesignation: "Assistant Professor",
      editorEmail: "editor_acst@ranvenpublisher.com",
      editorInstitute: "Indian Institute of Information Technology Sri City",
    },
  };
  return <div className="DisplayJournalsContainer">
    <JournalContactUsDetails content={content[jour] } />
  </div>;
}
