import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="FooterWrapperContainer">
      <div className="FooterWrapperContainerCopyright">
        <span className="FooterWrapperContainerCopyrightSpan">&#169;</span>{" "}
        Copyright 2024{" "}
        <span className="FooterWrapperContainerCopyrightSpanCompanyName">
          RanVen
        </span>{" "}
        Publishers : All rights are reserved to{" "}
        <span className="FooterWrapperContainerCopyrightSpanCompanyName">
          RanVen
        </span>{" "}
        Publishers
      </div>
      <div className="FooterWrapperContainerCopyright" style={{marginRight:"30px"}}>
        <a href="mailto:ranven@ranvenpublisher.com "><img className="FooterWrapperContainerCopyrightMailIcon" src="https://cdn-icons-png.flaticon.com/256/888/888853.png" alt="" /> Contact</a>
      </div>
    </div>
  );
}
