import React, { useEffect, useState } from "react";
import "../Display Journals/DisplayJournals.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../App";
import Spinner from "../../Components/Spinner/Spinner";
import Footer from "../../Components/Footer/Footer";
import { Helmet } from "react-helmet-async";

export default function Doi() {
  const { paperid } = useParams();
  const [paper, setPaper] = useState();
  const [journalname, setjournalname] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const getPaperDetails = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get(
          `${apiUrl}/api/v1/backdoor-upload/paper/${paperid}`
        );
        const data = res.data.paper;
        console.log(data);
        const url = data?.pdflink;
        const pattern =
          /https:\/\/www\.ranvenpublisher\.com\/Assets\/papers\/([A-Za-z]+)\//;

        const match = url.match(pattern);
        let result = match ? match[1] : "No match found";
        // console.log(result);
        if (result === "ITCEE") {
          result =
            "International Transactions on Computer and Electrical Engineering";
        } else if (result === "ITACST") {
          result =
            "International Transactions on Advanced Computer Science Technology";
        } else if (result === "ITEE") {
          result = "International Transactions on Electronic Engineering";
        } else if (result === "ITE") {
          result = "International Transactions On Engineering";
        }
        setjournalname(result);
        setPaper(data);
        setIsLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          navigate("/notfound");
        }
      }
    };
    getPaperDetails();
  }, []);

  return (
    <div className="DisplayJournalsContainer">
      {isLoading && !paper ? (
        <Spinner />
      ) : (
        <>
          <Helmet prioritizeSeoTags>
            <meta name="citation_title" content={paper?.papertitle} />
            <meta name="citation_author" content={paper?.authorname} />
            <meta
              name="citation_publication_date"
              content={`${paper?.createdAt.slice(0, 10).split("-").join("/")}`}
            />
            <meta
              name="citation_journal_title"
              content="International Transactions on Computer and Electrical Engineering"
            />
            <meta name="citation_volume" content="1" />
            <meta name="citation_issue" content="1" />
            <meta name="citation_firstpage" content="1" />
            <meta name="citation_lastpage" content="8" />
            <meta name="citation_pdf_url" content={paper?.pdflink} />
          </Helmet>

          <div className="DisplayJournalsMainContent">
            <Helmet>
              <meta name="citation_title" content={paper?.papertitle} />
              <meta name="citation_author" content={paper?.authorname} />
              <meta
                name="citation_publication_date"
                content={paper?.yearofpublication}
              />
              <meta name="citation_journal_title" content={journalname} />
              <meta name="citation_volume" content="1" />
              <meta name="citation_issue" content={"1"} />
              <meta name="citation_firstpage" content={paper?.firstpage} />
              <meta name="citation_lastpage" content={paper?.lastpage} />
              <meta name="citation_pdf_url" content={paper?.pdflink} />
              {paper?.references.map((reference) => {
                const author = reference.authors.join(",");
                //  console.log(author);
                return (
                  <meta
                    name="citation_reference"
                    content={`${author}. ${reference?.papertitle}. ${
                      reference.volume ? reference.volume : ""
                    }${reference.issue ? "(" + reference.issue + ")" : ""}${
                      reference.paper
                        ? ":" +
                          reference.paper.start +
                          "-" +
                          reference.paper.end +
                          ","
                        : ""
                    }${reference.year ? reference.year : ""}.`}
                  />
                );
              })}
            </Helmet>
            <div
              className="DisplayJournalsMainContentTitleHead"
              style={{ textDecoration: "none", textAlign: "start" }}
            >
              {paper && paper.papertitle}
            </div>
            <div className="DisplayJournalsMainContentLogo">
              <Link to={paper?.pdflink}>
                <img
                  src={
                    "https://www.iconpacks.net/icons/2/free-pdf-download-icon-2617-thumb.png"
                  }
                  alt={`Download PDF`}
                  style={{ height: "100px", width: "80px", cursor: "pointer" }}
                  className="DisplayJournalsMainContentLogoImg"
                />
              </Link>
              <div
                className="DisplayJournalsMainContentLinks"
                style={{ flexDirection: "column" }}
              >
                <div>
                  {" "}
                  <span style={{ color: "#286fb4", fontSize: "large" }}>
                    Authors :
                  </span>
                  <span style={{ marginLeft: "4px" }}>
                    {paper && paper.authorname}
                  </span>
                </div>
              </div>
            </div>
<div className="DisplayJournalsMainContentBodyHead">Abstract</div>
            <div
              className="DisplayJournalsMainContentBodyHeadPara"
              style={{ fontSize: "medium" }}
            >
              {paper && paper.abstract}
            </div>
            <div className="DisplayJournalsMainContentBodyHead">
              Published In
            </div>
            <div
              className="DisplayJournalsMainContentBodyHeadPara"
              style={{ fontSize: "medium" }}
            >
              {journalname} ( Volume Year:{" "} 
               {paper && paper.issue.volumeyear} , Volume Number:{" "} 
              {paper && paper.issue.volumenumber}, Issue Number:{" "}
              {paper && paper.issue.issuenumber})
            </div>
            <div className="DisplayJournalsMainContentBodyHead">DOI</div>
            <div
              className="DisplayJournalsMainContentBodyHeadPara"
              style={{ fontSize: "medium" }}
            >
              <Link to={`/doi/${paperid}`}> {paper && paper.Doi}</Link>
            </div>
            <div className="DisplayJournalsMainContentBodyHead">Publisher</div>
            <div
              className="DisplayJournalsMainContentBodyHeadPara"
              style={{ fontSize: "medium", marginBottom: "60px" }}
            >
              Ranven Publisher
            </div>
            {/* <div className="DisplayJournalsMainContentBodyHead">

            Date Of Publication
          </div>
          <div
            className="DisplayJournalsMainContentBodyHeadPara"
            style={{ fontSize: "medium", marginBottom: "20px" }}
          >
            {paper && paper.createdAt}
          </div> */}
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}
