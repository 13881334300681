import React from "react";
import "../../Pages/Display Journals/DisplayJournals.css";
const ITEImage = "/Assets/Images/ITE.jpg";
const ITEEImage = "/Assets/Images/ITEE.jpg";
const ITACSTImage = "/Assets/Images/ITACST.jpg";
const ITCEEImage = "/Assets/Images/ITCEE.jpg";

export default function JournalContactUsDetails({ content }) {
  return (
    <div className="DisplayJournalsContainer">
      <div className="DisplayJournalsMainContent">
        <div className="DisplayJournalsMainContentTitleHead">
          {content.title}{" "}
        </div>
        <div className="DisplayJournalsMainContentLogo">
          {content.shorttitle === "ITE" && (
            <img
              src={ITEImage}
              alt={`${content.shorttitle}`}
              className="DisplayJournalsMainContentLogoImg"
            />
          )}
          {content.shorttitle === "ITEE" && (
            <img
              src={ITEEImage}
              alt={`${content.shorttitle}`}
              className="DisplayJournalsMainContentLogoImg"
            />
          )}
          {content.shorttitle === "ITACST" && (
            <img
              src={ITACSTImage}
              alt={`${content.shorttitle}`}
              className="DisplayJournalsMainContentLogoImg"
            />
          )}
          {content.shorttitle === "ITCEE" && (
            <img
              src={ITCEEImage}
              alt={`${content.shorttitle}`}
              className="DisplayJournalsMainContentLogoImg"
            />
          )}
          {/* <div className="DisplayJournalsMainContentBodyHead">
          Editor-in-chief Contact Information{" "}
        </div> */}
          <div className="DisplayJournalsMainContentBodyHeadPara">
            <div
              className="DisplayJournalsMainContent"
              style={{
                width: "80%",
                marginTop: "0px",
                marginLeft: "7%",
                fontSize: "larger",
              }}
            >
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{
                  width: "95%",
                  marginBottom: "8px",
                  fontSize: "x-large",
                  color: "#286fb4",
                }}
              >
                Editor-in-chief Contact Information
              </div>
            </div>
            <div
              className="DisplayJournalsMainContent"
              style={{ width: "80%", marginTop: "0px", marginLeft: "10%" }}
            >
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "95%", marginTop: "3px", fontSize: "larger" }}
              >
                <b> Editor-in-Chief:</b> {content.editorName}
              </div>
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "95%", marginTop: "3px", fontSize: "larger" }}
              >
                <b>Designation:</b> {content.editorDesignation}
              </div>
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "95%", marginTop: "3px", fontSize: "larger" }}
              >
                <b>Email: </b> {content.editorEmail}
              </div>
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "95%", marginTop: "3px", fontSize: "larger" }}
              >
                <b>Institute: </b> {content.editorInstitute}
              </div>
            </div>
          </div>
        </div>
        <div className="DisplayJournalsMainContentBodyHead">
          Publisher Contact Information{" "}
        </div>
        <div
          className="DisplayJournalsMainContentBodyHeadPara"
          style={{ marginBottom: "20px", fontSize: "medium" }}
        >
          RanVen Publishing (OPC) Private Limited, <br />
          13th Ward, Opp Old SBM, Devanahalli, <br />
          Devanahalli, Bangalore Rural, Karnataka,562110 <br />
          <b>Email:</b> ranven@ranvenpublisher.com
        </div>
      </div>
    </div>
  );
}
