import React, { useState } from "react";
import "../../Components/PaperContainer/PaperContainer.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./TbPaperContainer.css";

const img = "/Assets/Images/scholarlyjournals_src.jpg";
const TbPaperContainer = ({ vid,uniquempkey, selectedVidId }) => {
  // console.table([vid,uniquempkey,selectedVidId]);
  return (
    <div className=" tbPaperContainer">
      <div className="PaperContainer_icon">
        <div className="iconcontainer" style={{ width: "100%",background:"transparent",height:"90%",borderRadius:"2px" }}>
          <img src={img} alt="" style={{ width: "75%", height: "75%" }} />
        </div>
      </div>
      <div className="tbpaper">
        <div className="tbheading">
          {" "}
         Volume : &nbsp;
         {uniquempkey}
	
       </div>
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        <div className="tbheading">
          {" "}
       Year :  {vid.volumeyear}
        </div>
      </div>
      <div className="tbpaperarrow-icon">
        {selectedVidId === uniquempkey ? <FaChevronUp /> : <FaChevronDown />}
      </div>
    </div>
  );
};

export default TbPaperContainer;
