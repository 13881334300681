import React, { useRef } from "react";
import "../../Pages/Display Journals/DisplayJournals.css";
import { Link, useNavigate } from "react-router-dom";
import NormalBtn from "../Buttons/NormalBtn/NormalBtn";
const ITEImage = "/Assets/Images/ITE.jpg";
const ITEEImage = "/Assets/Images/ITEE.jpg";
const ITACSTImage = "/Assets/Images/ITACST.jpg";
const ITCEEImage = "/Assets/Images/ITCEE.jpg";

export default function JournalDetails({ content, email }) {
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const openpdf = () => {
    if (content.shorttitle === "ITE") {
      window.open("/Assets/Guidelines/Guidelines_ITE.pdf", "_blank");
    } else if (content.shorttitle === "ITEE") {
      window.open("/Assets/Guidelines/Guidelines_EE.pdf", "_blank");
    } else if (content.shorttitle === "ITACST") {
      window.open("/Assets/Guidelines/Guidelines_ACST.pdf", "_blank");
    } else if (content.shorttitle === "ITCEE") {
      window.open("/Assets/Guidelines/Guidelines_CEE.pdf", "_blank");
    }
  };
  const openContactInfo = () => {
    if (content.shorttitle === "ITACST") {
      navigate("/display/journals/itacst/contactus");
    } else if (content.shorttitle === "ITCEE") {
      navigate("/display/journals/itcee/contactus");
    }
  };
  const downloadzipfile = () => {
    const url = content.url;
    const fileName = url.split("/").pop();
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };
  const handleEditorial = () => {
    navigate("/display/journals/editorialBoard");
  };
  const open_License = () => {
    window.open(
      "/Assets/Coyprights_License_and_Agreement/Copyrights_License_and_Agreement.pdf"
    );
  };
  const open_Publishing_Policy = async () => {
    window.open("/Assets/Publishing_policy/Publication Policies.pdf");
  };
  const scrollToTarget = () => {
    // console.log(scrollRef);
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const goToAllIssuePage = () => {
    navigate(`/tb_content/${content.id}`, { state: { content: content } });
  };

  return (
    <div className="DisplayJournalsContainer">
      <div className="DisplayJournalsMainContent">
        <div className="DisplayJournalsMainContentTitleHead">
          {content.title}{" "}
          {/* <span className="DisplayJournalsMainContentTitleHeadSpan">
            ({content.shorttitle})
          </span> */}
        </div>
        <div className="DisplayJournalsMainContentLogo">
          {content.shorttitle === "ITE" && (
            <img
              src={ITEImage}
              alt={`${content.shorttitle}`}
              className="DisplayJournalsMainContentLogoImg"
            />
          )}
          {content.shorttitle === "ITEE" && (
            <img
              src={ITEEImage}
              alt={`${content.shorttitle}`}
              className="DisplayJournalsMainContentLogoImg"
            />
          )}
          {content.shorttitle === "ITACST" && (
            <img
              src={ITACSTImage}
              alt={`${content.shorttitle}`}
              className="DisplayJournalsMainContentLogoImg"
            />
          )}
          {content.shorttitle === "ITCEE" && (
            <img
              src={ITCEEImage}
              alt={`${content.shorttitle}`}
              className="DisplayJournalsMainContentLogoImg"
            />
          )}
          <div className="DisplayJournalsMainContentLinks">
            <div className="DisplayJournalsMainContentFirstDiv"> </div>
            <div className="DisplayJournalsMainContentLinksBtns">
              {/* <Link to="/tb_content"> */}
              <span onClick={goToAllIssuePage}>
                <NormalBtn info="Archives" />
              </span>
              {/* </Link> */}
              <span onClick={downloadzipfile}>
                <NormalBtn info={`LaTex Templates`} btnstyle={`stl`} />
              </span>
              <span onClick={openpdf}>
                <NormalBtn info="Guideline for Authors" btnstyle={`stl`} />
              </span>
              <span onClick={scrollToTarget}>
                <NormalBtn info="Submit Manuscript" btnstyle={`stl`} />
              </span>
              <span onClick={openContactInfo}>
                <NormalBtn info="Contact Information" btnstyle={`stl`} />
              </span>
              <NormalBtn info="Abstract & Indexed" btnstyle={`disabled stl`} />
            </div>
          </div>
        </div>
        <div className="DisplayJournalsMainContentBodyHead">Introduction </div>
        {content.intro.map((paragraph, ind) => (
          <div className="DisplayJournalsMainContentBodyHeadPara" key={ind}>
            {paragraph}
          </div>
        ))}
        <div className="DisplayJournalsMainContentBodyHead">Aims & Scope </div>
        {content.aim.map((paragraph, ind) => (
          <div className="DisplayJournalsMainContentBodyHeadPara" key={ind}>
            {paragraph}
          </div>
        ))}
        {content.sc && (
          <div className="DisplayJournalsMainContentBodyHead">
            Subject Coverage{" "}
          </div>
        )}
        {content.sc && (
          <div className="DisplayJournalsMainContentBodyHeadPara">
            {content.sc.description}
          </div>
        )}
        {content.sc &&
          content.sc.lists.map((comp, ind) => (
            <div
              className="DisplayJournalsMainContent"
              key={ind}
              style={{ width: "100%", marginTop: "0px" }}
            >
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "90%" }}
              >
                {comp.title}
              </div>
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "88%" }}
              >
                <ul className="DisplayJournalsMainContentBodyHeadParaUl">
                  {comp.contents.map((elements, i) => (
                    <li key={10 * ind + i}>{elements}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        <div className="DisplayJournalsMainContentBodyHead" ref={scrollRef}>
          Submit Manuscript
        </div>
        <div className="DisplayJournalsMainContentBodyHeadPara">
          All the manuscripts should submit through the following email id:{" "}
          <a
            href={`mailto:${content.cheifEditorEmail}`}
            style={{ textDecoration: "none" }}
          >
            {content.cheifEditorEmail}
          </a>
        </div>
        <div className="DisplayJournalsMainContentBodyHead">
          Editorial Board
          {/* <u id ="Editorial_board" onClick={handleEditorial}> The Editorial Board :</u> */}
        </div>
        <span
          id="Copyrightinfo"
          onClick={handleEditorial}
          style={{ width: "100%", fontSize: "12px" }}
        >
          Please click here for more details{" "}
        </span>
        <div className="DisplayJournalsMainContentBodyHeadPara">
          {content.cheifEditor.map((name, ind) => (
            <div
              className="DisplayJournalsMainContent"
              key={ind}
              style={{ width: "100%", marginTop: "0px" }}
            >
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "95%", marginTop: "3px" }}
              >
                <b> Editor-in-Chief:</b> {name}
              </div>
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "95%", marginTop: "0px" }}
              >
                <b>Designation:</b> {content.Designation[ind]}
              </div>
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "95%", marginTop: "0px" }}
              >
                <b>Email:</b> {content.cheifEditorEmail[ind]}
              </div>
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "95%", marginTop: "0px" }}
              >
                <b>Institute:</b> {content.Institute[ind]}
              </div>
              <div
                className="DisplayJournalsMainContentBodyHeadPara"
                style={{ width: "95%", marginTop: "0px" }}
              >
                <b>Profile:</b>{" "}
                <Link to={content.Profile[ind]}>{content.Profile[ind]}</Link>
              </div>
            </div>
          ))}
        </div>
        <div className="DisplayJournalsMainContentBodyHead">
          Copyrights,licence and Agreement
        </div>
        <div
          className="DisplayJournalsMainContentBodyHeadPara"
          style={{ marginBottom: "0px" }}
        >
          &#169; Copyright and Agreement is mandatory to publish article with
          RanVen publishers. <br />
          <span id="Copyrightinfo" onClick={open_License}>
            More info...
          </span>
          .
        </div>
        <div className="DisplayJournalsMainContentBodyHead">
          Publication Policy
        </div>
        <div
          className="DisplayJournalsMainContentBodyHeadPara"
          style={{ marginBottom: "0px" }}
        >
          Review the publishing policy of RanVen publisher.
          <br />
          <span id="Copyrightinfo" onClick={open_Publishing_Policy}>
            More info...
          </span>
          .
        </div>
        <div className="DisplayJournalsMainContentBodyHead">
          Publication Schedule
        </div>
        <div
          className="DisplayJournalsMainContentBodyHeadPara"
          style={{ marginBottom: "0px" }}
        >
          This journal publishes 12 issues annually, releasing one issue per
          month.{" "}
        </div>

        <div className="DisplayJournalsMainContentBodyHead">Publisher </div>

        <div
          className="DisplayJournalsMainContentBodyHeadPara"
          style={{ fontSize: "medium", marginBottom: "20px" }}
        >
          <div
            className="DisplayJournalsMainContentBodyHeadPara"
            style={{ marginBottom: "0px" }}
          >
            <b> Address</b> RanVen Publisher, Bangalore-562110, Karnataka,
            India.
            <br />
            <a
              href="mailto:ranven@ranvenpublisher.com"
              style={{ color: "#286fb4" }}
            >
              ranven@ranvenpublisher.com
            </a>
          </div>
        </div>

        <div
          className="DisplayJournalsMainContentBodyHead"
          style={{ display: "flex", gap: "1em", marginBottom: "26px" }}
        >
          <div className="issn_footer_details">
            Starting Year: <span>2024</span>{" "}
          </div>
          <div className="issn_footer_details">
            Publication Format: <span>Online </span>
          </div>
          <div className="issn_footer_details">
            Language: <span>English </span>
          </div>
          <div className="issn_footer_details">
            ISSN: <span>NA </span>
          </div>
        </div>
      </div>
    </div>
  );
}
